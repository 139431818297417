<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar @search="search"></searchBar>
        <div class="title bgf flex">
            <div class="fs_18 bold color_333 flex_start">
                <span>{{ title }}</span>
            </div>
            <!-- <router-link to="/position/post" class="btn flex_cen">
                <img src="@/assets/imgs/icon/post-icon.png" class="post-icon" alt="" />
                <span class="fs_14 color">发布职位</span>
            </router-link> -->
        </div>
        <div class="subtitle flex">
            <div class="flex_c">
                <div @click="$router.replace(`/position/collection/${id}/${title}`)" class="button color_999 fs_14 flex_cen btn mr_10">
                    <span class="center">已投递 ({{ data.apply_num || 0 }})</span>
                </div>
                <div class="button color_999 fs_14 flex_cen btn mr_10 active">
                    <span class="center">已邀请面试 ({{ data.interview_num || 0 }})</span>
                </div>
                <div @click="$router.replace(`/position/entry/${id}/${title}`)" class="button color_999 fs_14 flex_cen btn mr_10">
                    <span class="center">已入职 ({{ data.join_num || 0 }})</span>
                </div>
            </div>
        </div>
        <el-row class="list" :gutter="10">
            <el-col class="item mt_10" v-for="(item, index) in list" :key="index" :span="12">
                <el-card :body-style="{ padding: '0px' }" shadow="hover" class="btn" @click.stop="detail(item.user_id)">
                    <div class="top flex_c bottom_link">
                        <el-avatar shape="circle" :size="60" :fit="'contain'" :src="item.avatar" class="mr_20">
                            <img :src="item.gender == '男' ? $store.state.nanAvatar : $store.state.nvAvatar" />
                        </el-avatar>
                        <div class="flex_col flex_1">
                            <div class="flex">
                                <div class="bold">
                                    <span class="fs_20 color_333 mr_10">{{ item.realname }}</span>
                                    <span class="fs_14 color_000">{{ item.mobile }}</span>
                                </div>
                                <div class="flex_cen">
                                    <div class="flex_cen protect-btn btn mr_10" @click.stop="protect(item.id, index)">
                                        <p class="protect-icon"></p>
                                        入职保护
                                    </div>
                                    <el-tooltip content="" placement="top" effect="light" popper-class="custom-tip">
                                        <template #content>
                                            点击入职保护后，该员工状态将会更新为已入职，并且其<br />信息将不会在人才大厅中展示，直到其从贵公司离职。
                                        </template>
                                        <p class="el-icon-warning-outline info_color"></p>
                                    </el-tooltip>
                                </div>
                                <div class="fs_16 color_333">{{ item.pick_time }}</div>
                            </div>
                            <p class="flex_c fs_14 color_333 use_tip mt_10">
                                <span class="line" v-if="item.current_city">{{
									item.current_city
								}}</span>
                                <span class="line" v-if="item.gender">{{ item.gender }}</span>
                                <span class="line" v-if="item.age">{{ item.age }}</span>
                                <span class="line" v-if="item.work_exp">{{
									item.work_exp
								}}</span>
                                <span class="line" v-if="item.education">{{
									item.education
								}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="bottom">
                        <p class="flex_c fs_14 color_666">
                            <span class="label">联系人员：</span>
                            <span class="line one">{{ item.contacts_name }}</span>
                        </p>
                        <p class="flex_c fs_14 color_666 mt_10">
                            <span class="label">联系方式：</span>
                            <span class="line one">{{ item.contacts_tel }}</span>
                        </p>
                        <p class="flex_c fs_14 color_666 mt_10">
                            <span class="label">面试地点：</span>
                            <span class="line one">{{ item.address }}</span>
                        </p>
                        <p class="flex_c fs_14 color_666 mt_10">
                            <span class="label">备注信息：</span>
                            <span class="line one">{{ item.remark || "-" }}</span>
                        </p>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <empty :finish="finish" :length="list.length"></empty>

        <protect-dialog ref="protectDialog"></protect-dialog>
        <resumeInfo ref="resumeInfo"></resumeInfo>
    </div>
</template>
<script>
import protectDialog from "./components/protect-dialog"
import resumeInfo from "../talents-hall/dialog/resume-info.vue"
export default {
	name: "PositionInviteAudition",
	components: { protectDialog, resumeInfo },
	data() {
		return {
			keywords: "",
			id: "",
			title: "",
			list: [],
			limit: 20,
			page: 1,
			finish: false,
			url: require("@/assets/imgs/index/2.png"),
			data: {
				apply_num: 0,
				interview_num: 0,
				join_num: 0
			}
		}
	},
	created() {
		this.id = this.$route.params.id
		this.title = this.$route.params.title
		this.getTabNum()
		this.getList()
	},
	methods: {
		load() {
			this.page++
			this.getList()
		},
		getTabNum() {
			this.$axios({
				url: `/ent/v3/job/tab_num/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					this.data = res.data
				}
			})
		},
		getList() {
			this.$axios({
				url: `/ent/v3/job/invite_list/${this.id}`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					keywords: this.keywords
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		detail(id) {
			this.$axios({
				url: `/ent/v3/talent/view_user/${id}`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.handleDialog("resumeInfo", res.data, id)
				}
			})
		},
		search(v) {
			this.keywords = v
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		handleDialog(dialog, data, id) {
			this.$refs[dialog].dialogVisible = true
			this.$refs[dialog].data = data
			this.$refs[dialog].id = id
			// this.$refs[dialog].init()
		},
		protect(id, index) {
			// this.$refs.protectDialog.dialogVisible = true
			this.$refs.protectDialog.id = id
			this.$refs.protectDialog.init()
			this.$refs.protectDialog.index = index //移除当前列表位置
		}
	}
}
</script>
<style lang="less" scoped>
.subtitle {
    .button {
        width: 130px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 25px;
        &.active {
            border-color: #1890ff;
            color: #1890ff;
        }
    }
}
.title {
    padding: 18px 40px 18px 40px;
    margin-bottom: 10px;

    .post-icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}
.list {
    .item {
        .top,
        .bottom {
            padding: 20px 30px;
        }
        .top {
            .label {
                width: 100px;
                color: #ccc;
            }
        }
        .bottom {
            .line.one {
                width: 85%;
            }
        }
        .protect-btn {
            width: 82px;
            height: 22px;
            border-radius: 22px;
            border: 1px solid #ededed;
            font-size: 12px;
            color: #a2aec7;
            transition: all .2s ease;
            .protect-icon {
                width: 11px;
                height: 14px;
                margin-right: 2px;
                background: url(../../assets/imgs/icon/protect-icon1.png)
                    no-repeat;
                background-size: cover;
                transition: all .2s ease;
            }
            &:hover {
                color: #fff;
                background: #5ab0ff;
                border-color: #5ab0ff;
                transition: all .2s ease;
                .protect-icon {
                    background: url(../../assets/imgs/icon/protect-icon.png)
                        no-repeat;
                    background-size: cover;
                    transition: all .2s ease;
                }
            }
        }
    }
}
/deep/.el-card {
    border-radius: 0;
    .el-card.is-hover-shadow:hover {
        box-shadow: 0px 3px 12px rgba(162, 162, 162, 0.16);
    }
}
</style>
